import { Component, OnInit, Input } from '@angular/core';
import { faGithub, faInstagram, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { I18nService } from 'src/app/services/i18n.service';
import { DataModel } from 'src/app/models/texts-model';
import { getDataModel } from 'src/app/core/functions/get_data_model';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {
  faGithub = faGithub;
  faFacebook = faFacebook;
  faLinkedIn = faLinkedin;
  titles: DataModel;
  @Input() isCol:boolean;
  @Input() isInitialPos: boolean;
  constructor(public i18n: I18nService, private dbService: DatabaseService,) { }

  ngOnInit() {
    this.titles = getDataModel('titles', this.dbService);
  }

}
