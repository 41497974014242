import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faWhatsapp, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faComments, faEnvelope, faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { DatabaseService } from 'src/app/services/database.service';
import { DataModel } from 'src/app/models/texts-model';
import { I18nService } from 'src/app/services/i18n.service';
import { getDataModel } from 'src/app/core/functions/get_data_model';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  faEnvelope = faEnvelope;
  faPhone = faPhoneAlt;
  faMapMarkerAlt = faMapMarkerAlt;
  faWhatsapp = faWhatsapp;
  faTelegram = faTelegram;
  faComments = faComments;
  phone: DataModel;
  address: DataModel;
  titles: DataModel;
  constructor(private titleService: Title,
    private dbService: DatabaseService,
    public i18n: I18nService
  ) {
    this.titleService.setTitle("i-Delub Contact");

  }

  ngOnInit() {
    this.phone = getDataModel('phone', this.dbService);
    this.address = getDataModel('address', this.dbService);
    this.titles = getDataModel('titles', this.dbService);
  }

}
