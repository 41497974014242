import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { DataModel } from 'src/app/models/texts-model';
import { DatabaseService } from 'src/app/services/database.service';
import { I18nService } from 'src/app/services/i18n.service';
import { getDataModel } from 'src/app/core/functions/get_data_model';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  titles: DataModel;
  constructor(private titleService:Title, private dbService: DatabaseService, public i18n: I18nService) { 
    this.titleService.setTitle("idelub home");
  }

  ngOnInit() {
    this.titles = getDataModel('titles', this.dbService);
  }

}
