import { Component, OnInit, DoCheck } from '@angular/core';
import { HostListener } from '@angular/core';
import { DatabaseService } from './services/database.service';
import { I18nService } from './services/i18n.service';
import { DataModel } from './models/texts-model';
import { getDataModel } from 'src/app/core/functions/get_data_model';
import { RouterOutlet } from '@angular/router';
import { fader, stepper, transformer, slider } from './route-animation';
import { MenuService } from './services/menu.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fader]
})
export class AppComponent implements OnInit, DoCheck {
  showMenu = false;
  showLang = false;
  loaded = false;
  titleReachTop = false;
  title = 'idelub';
  actualLang: string;
  actualAsset: string;
  screenWidth: number;
  routes: DataModel;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  constructor(public dbService: DatabaseService, public i18n: I18nService, protected menu:MenuService) {
    this.dbService.setTexts();
    this.dbService.setProjects();

    this.onResize();
  }

  ngOnInit() {
    var userLang = navigator.language;

    this.i18n.setLang(userLang.split('-')[0]);
    this.actualLang = this.i18n.getLang();
    this.actualAsset = `../assets/icons/${this.actualLang}.png`;
  }

  ngDoCheck() {
    if (this.dbService.texts.length > 0 && this.routes === undefined) {
      this.routes = getDataModel('routes', this.dbService);
    }
    this.titleReachTop = this.menu.paintMenuBackground;

  }

  

  animateMenu() {
    if (this.screenWidth < 768) {
      this.showMenu = !this.showMenu;
    } else {
      this.showMenu = false;
    }
  }

  closeMenu() {
    if (this.screenWidth < 768) {
      this.showMenu = !this.showMenu;
    } else {
      this.showMenu = false;
    }
  }

  animateLang() {
      this.showLang = !this.showLang;
  }

  closeLang() {
      this.showLang = !this.showLang;
  }

  setLang(lang: string) {
    this.i18n.setLang(lang);
    this.actualLang = this.i18n.getLang();
    this.actualAsset = `../assets/icons/${this.actualLang}.png`;
  }
}
