import { Component, OnInit, Input } from '@angular/core';
import { ProjectModel } from 'src/app/models/project-model';
import { I18nService } from 'src/app/services/i18n.service';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {
  @Input() project: ProjectModel;
  constructor(private i18n: I18nService) { }

  ngOnInit() {
    console.log(this.project);
  }

}
