import { Injectable } from '@angular/core';

const availableLang = ['fr','es','cat','en'];

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private lang: string = "fr";
  
  constructor() { }

  setLang(language: string) {
    this.lang = availableLang.includes(language) ? language : 'fr';
  }

  getLang(){
    return this.lang;
  }
}
