import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  paintMenuBackground: boolean;

  constructor() { }

  setBackGroundtoDark(value: boolean):void {
    console.log("Setting with value: ", value);
    this.paintMenuBackground = value;
  }
}
