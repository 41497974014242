import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ProjectModel } from 'src/app/models/project-model';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
@Component({
  selector: 'app-ovleray',
  templateUrl: './ovleray.component.html',
  styleUrls: ['./ovleray.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({opacity:0.3}),
        animate('1s ease-in')
      ]),
      transition(':leave', [
        style({opacity: 0}),
        animate('0.5s ease-out')
      ]),
    ]),
  ],
  
})
export class OvlerayComponent implements OnInit {
  @Input() project: ProjectModel;
  @Input() isDescription: boolean;
  @Input() isCarousel: boolean;
  @Output() onClose:EventEmitter<boolean> = new EventEmitter<boolean>();
  faCross = faTimes;
  slideIndex: number = 1;
  show: boolean;
  constructor() { }

  ngOnInit() {
  }

  onOverlayClose() {
    this.project = null;
    this.onClose.emit(false);
  }

  
}
