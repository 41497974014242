import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { faGithub, faInstagram, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import {Title} from '@angular/platform-browser';
import { DataModel } from 'src/app/models/texts-model';
import { DatabaseService } from 'src/app/services/database.service';
import { I18nService } from 'src/app/services/i18n.service';
import { getDataModel } from 'src/app/core/functions/get_data_model';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-aboutme',
  templateUrl: './aboutme.component.html',
  styleUrls: ['./aboutme.component.scss']
})
export class AboutmeComponent implements OnInit {
  faGithub = faGithub;
  faFacebook = faFacebook;
  faLinkedIn = faLinkedin;
  titles: DataModel;
  about: DataModel;
  
  @HostListener('window:scroll', ['$event']) 
    onScroll(event) {
      // console.debug("Scroll Event", document.body.scrollTop);
      // see András Szepesházi's comment below
      let distanceToMenu = 2 * parseFloat(getComputedStyle(document.documentElement).fontSize) * 2;
      this.menu.setBackGroundtoDark(window.pageYOffset >= distanceToMenu);
    }
    
  constructor(
      private titleService: Title,
      private dbService: DatabaseService,
      public i18n: I18nService,
      private menu: MenuService
    ) {
    this.titleService.setTitle('i-Delub about Arnaud Delubac');
   }

  ngOnInit() {
    this.titles = getDataModel('titles', this.dbService);
    this.about = getDataModel('about', this.dbService);
  }

}
