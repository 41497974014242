import { Component, OnInit, Input } from '@angular/core';
import { ProjectModel } from 'src/app/models/project-model';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss'],
})
export class SlideshowComponent implements OnInit {
  @Input() project: ProjectModel;
  slideIndex: number = 1;
  isSliding:boolean = false;
  constructor() { }

  ngOnInit() {
  }
  plusSlides(n:number) {
    this.showSlides(this.slideIndex + n);
  }

  currentSlide(n: number){
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n:number) {
    console.log("Showing slides");
    this.isSliding = true;
    //setTimeout(() => null, 500);
      if (n > this.project.screenshots.length) {this.slideIndex = 1}
      else if (n < 1) {this.slideIndex = this.project.screenshots.length}
      else {
        this.slideIndex = n;
      }
    this.isSliding = false;
  }

  onAnimationEvent ( event: AnimationEvent ) {
    console.log(event);
  }
}
