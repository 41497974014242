import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { faEye, faSpider, faTimes, faEnvelopeOpenText, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { DataModel } from 'src/app/models/texts-model';
import { getDataModel } from 'src/app/core/functions/get_data_model';
import { DatabaseService } from 'src/app/services/database.service';
import { I18nService } from 'src/app/services/i18n.service';
import { ProjectModel } from 'src/app/models/project-model';


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  
})
export class ProjectsComponent implements OnInit {
  faEye = faEye;
  faGithub = faGithub;
  faSpider = faSpider;
  faCross = faTimes;
  faText = faEnvelopeOpenText;
  construction = faLaptopCode;
  titles: DataModel;
  project: DataModel;
  projectItem: ProjectModel;
  projects: ProjectModel[];
  showOverlay: boolean = false;
  screenshots: String[] = [];
  isCarousel = false;
  isDescription = false;
  constructor(private titleService: Title, private dbService: DatabaseService, public i18n: I18nService) {
    this.titleService.setTitle("i-Delub Arnaud Delubac's projects");
  }

  ngOnInit() {
    this.titles = getDataModel('titles', this.dbService);
    this.projects = this.dbService.projects;
    this.project = getDataModel('projects', this.dbService);
  }

  onOverlayAction(project: ProjectModel, isCarousel: boolean, isDescription: boolean){
    this.projectItem = project;
    this.isCarousel = isCarousel;
    this.isDescription = isDescription;
    this.showOverlay = !this.showOverlay;
  }

  

}
