import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutmeComponent } from './pages/aboutme/aboutme.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, data: {animation: 'homePage'} },
  { path: 'about-me', component: AboutmeComponent, data: {animation: 'aboutPage'} },
  { path: 'projects', component: ProjectsComponent, data: {animation: 'projectsPage'} },
  { path: 'contact', component: ContactComponent, data: {animation: 'contactPage'} },
  { path: 'privacy', component: PrivacyComponent, data: {animation: 'redirectPage'} },
  { path: '',   redirectTo: '/home', pathMatch: 'full', data: {animation: 'redirectPage'}  }, // redirect to `first-component`
  { path: '**', component: HomeComponent  },
  //{ path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
