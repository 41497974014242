import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, QueryDocumentSnapshot, DocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { Observable, Subscription, Subscriber, Subject } from 'rxjs';
import { TextsModel } from '../models/texts-model';
import { ProjectModel } from '../models/project-model';
@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  texts:TextsModel[] = [];
  projects:ProjectModel[] = [];
  constructor(private db: AngularFirestore) {
   }

  setTexts() {
    const snapshot = this.db.collection('texts').snapshotChanges();
    snapshot.subscribe(
      (textSnap) => {
        textSnap.forEach(
          (textData) => {
            this.texts
              .push({
              id: textData.payload.doc.id,
              data: textData.payload.doc.data()
            })
          },
        );
        return true;
      },
      (err) => {},
      () =>  true
    );
  }

  setProjects(){
    const snapshot = this.db.collection('projects', ref => ref.where('display', '==', true)
      .orderBy('position')).snapshotChanges();
    snapshot.subscribe(
      (projectSnap) => {
        this.projects = [];
        projectSnap.forEach(
          (projectData) => {
            const project: any= projectData.payload.doc.data();
            this.projects.push({
              id: projectData.payload.doc.id,
              web: project.web,
              github: project.github,
              image: project.image,
              screenshots: project.screenshots,
              description: project.description,
              ongoing: project.ongoing,
            })
          },
        );
        return true;
      },
      (err) => {},
      () => true
    )
  }
  
}
