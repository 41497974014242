// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCFeZlbjupggqZmo3dOYayN2LzAyAoqtV8",
    authDomain: "idelub-cc57e.firebaseapp.com",
    databaseURL: "https://idelub-cc57e.firebaseio.com",
    projectId: "idelub-cc57e",
    storageBucket: "idelub-cc57e.appspot.com",
    messagingSenderId: "1016669712636",
    appId: "1:1016669712636:web:8ed0458ca08304640e6779",
    measurementId: "G-VV71PECYV4"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
