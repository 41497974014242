import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faStackOverflow, faGithub, faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { AboutmeComponent } from './pages/aboutme/aboutme.component';
import { HomeComponent } from './pages/home/home.component';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { OvlerayComponent } from './pages/projects/ovleray/ovleray.component';
import { SlideshowComponent } from './pages/projects/ovleray/slideshow/slideshow.component';
import { DescriptionComponent } from './pages/projects/ovleray/description/description.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

const config = {
  apiKey: "AIzaSyCFeZlbjupggqZmo3dOYayN2LzAyAoqtV8",
  authDomain: "idelub-cc57e.firebaseapp.com",
  databaseURL: "https://idelub-cc57e.firebaseio.com",
  projectId: "idelub-cc57e",
  storageBucket: "idelub-cc57e.appspot.com",
  messagingSenderId: "1016669712636",
  appId: "1:1016669712636:web:8ed0458ca08304640e6779",
  measurementId: "G-VV71PECYV4"
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutmeComponent,
    PageFooterComponent,
    ProjectsComponent,
    ContactComponent,
    OvlerayComponent,
    SlideshowComponent,
    DescriptionComponent,
    PrivacyComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    AppRoutingModule,
    FontAwesomeModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule // storage
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(private library: FaIconLibrary) {
    library.addIcons(faFacebook, faLinkedin, faInstagram, faStackOverflow, faGithub,);
  }
}
